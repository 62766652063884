.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  box-sizing: border-box;
}

.AppContent {
  margin: auto;
  width: 100%;
  position: relative;
}

.AppLogo {
  height: 30vmin;
  min-height: 150px;
  pointer-events: none;
  margin-bottom: 1em;
}

.Dashboard {
  width: 100%;
}

.DashboardGauges {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.DashboardItem {
  width: 90px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.DashboardItemIcon {
  color: #479;
  opacity: 1;
}

.DashboardItem h1 {
  font-size: 120%;
}

.DashboardInfo {
  color: #479;
  font-size: 80%;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DashboardInfo svg {
  width: 0.5em !important;
  vertical-align: middle;
  margin-right: 0.2em;
}

.DashboardInfoIcon {
  margin-right: 0.5em;
}

.subzero {
  color: #7cf;
}

.DasboardBatteryStatus {
  font-size: 70%;
  color: rgba(100, 185, 255, 0.4);
  position: absolute;
  top: 0;
  right: 1em;
}

.DashboardBatteryStatusIcon {
  margin-right: 0.5em;
}

.QtieChartWrapper {
  margin: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.DashboardChartRange {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.DashboardChartRange div {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.2);
  border: 0px solid rgba(0, 0, 0, 0);
  color: #479;
  font-size: 70%;
  font-weight: 800;
  margin: 0 0.5em;
  padding: 0.2em 1em;
  border-radius: 1em;
  transition: border 0.3s;
}

.DashboardChartRange div:hover,
.DashboardChartRange div.DashboardChartRangeSelected {
  background-color: #479;
  color: #111;
}

@media (max-width: 500px), (max-height: 768px) {
  .DashboardItem {
    font-size: 90%;
  }

  .AppLogo {
    height: 10vmin;
  }
}
