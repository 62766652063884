.EnergyStats {
  background-color: #000f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 500px;
  gap: 1rem;
}

.rtSection {
  background-color: #222e;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
}

.rt h2 {
  margin: 0;
  margin-bottom: 1rem;
  color: rgb(103, 119, 133);
  font-size: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

.rt .values {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 80%;
  font-weight: 700;
}

.updated {
  font-size: 80%;
  margin: 2rem 0;
  color: #579e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updated svg {
  width: 0.5em !important;
  vertical-align: middle;
  margin-right: 0.2em;
}

.chart {
  width: 100%;
  flex-grow: 0;
}

.close {
  background: transparent;
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  font-size: 200%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
  border-radius: 100%;
  border: 0;
  appearance: none;
  line-height: 0;
  position: absolute;
  right: 2rem;
  top: 2rem;
}
